.m-t-10 {
    margin-top: 10px;
}

.m-t-2 {
    margin-top: 2px;
}

.m-t-12 {
    margin-top: 12px;
}

.m-t-15 {
    margin-top: 15px;
}

.m-t-20 {
    margin-top: 20px;
}

.m-t-25 {
    margin-top: 25px;
}

.m-t-30 {
    margin-top: 30px;
}

.m-t-40 {
    margin-top: 40px;
}

.m-t-2 {
    margin-top: 2px;
}

.m-t-5 {
    margin-top: 5px;
}

.m-t-50 {
    margin-top: 50px;
}

.m-t-60 {
    margin-top: 60px;
}

.m-t-70 {
    margin-top: 70px;
}

.m-t-80 {
    margin-top: 80px;
}

.m-t-90 {
    margin-top: 90px;
}

.m-t-120 {
    margin-top: 120px;
}
.m-t-130 {
    margin-top: 130px;
}
.m-t-150 {
    margin-top: 150px;
}
.m-t-170 {
    margin-top: 170px;
}
.m-l-5 {
    margin-left: 5px;
}
.m-l-10 {
    margin-left: 10px;
}
.m-l-15 {
    margin-left: 15px;
}

.m-l-20 {
    margin-left: 20px;
}

.m-l-30 {
    margin-left: 30px;
}

.m-l-40 {
    margin-left: 40px;
}

.m-l-50 {
    margin-left: 50px;
}

.m-r-10 {
    margin-right: 10px;
}

.m-r-20 {
    margin-right: 20px;
}

.m-r-30 {
    margin-right: 30px;
}

.m-r-40 {
    margin-right: 40px;
}

.m-r-50 {
    margin-right: 50px;
}

.m-b-10 {
    margin-bottom: 10px;
}

.m-b-15 {
    margin-bottom: 15px;
}

.m-b-20 {
    margin-bottom: 20px;
}

.m-b-30 {
    margin-bottom: 30px;
}

.m-b-40 {
    margin-bottom: 40px;
}

.m-b-50 {
    margin-bottom: 50px;
}

.m-b-70 {
    margin-bottom: 70px;
}

.m-b-5 {
    margin-bottom: 5px;
}

.m-b-100 {
    margin-bottom: 100px;
}

.m-t-100 {
    margin-top: 100px;
}

.m-b-200 {
    margin-bottom: 200px;
}

.m-t-200 {
    margin-top: 200px;
}

.m-t-300 {
    margin-top: 300px;
}

.m-b-150 {
    margin-bottom: 150px;
}

.p-h-10 {
    padding-left: 10px;
    padding-right: 10px;
}

.p-h-15 {
    padding-left: 15px;
    padding-right: 15px;
}

.p-h-20 {
    padding-left: 20px;
    padding-right: 20px;
}

.p-h-30 {
    padding-left: 30px;
    padding-right: 30px;
}

.p-h-50 {
    padding-left: 50px;
    padding-right: 50px;
}

.p-h-70 {
    padding-left: 70px;
    padding-right: 70px;
}

.p-h-75 {
    padding-left: 75px;
    padding-right: 75px;
}

.p-h-80 {
    padding-left: 80px;
    padding-right: 80px;
}

.p-h-90 {
    padding-left: 90px;
    padding-right: 90px;
}

.p-h-100 {
    padding-left: 100px;
    padding-right: 100px;
}

.p-h-120 {
    padding-left: 120px;
    padding-right: 120px;
}

.p-h-140 {
    padding-left: 140px;
    padding-right: 140px;
}

.p-h-200 {
    padding-left: 160px;
    padding-right: 200px;
}

.p-2 {
    padding: 2px
}

.p-3 {
    padding: 3px
}

.p-5 {
    padding: 5px
}

.p-10 {
    padding: 10px
}

.p-15 {
    padding: 15px
}

.p-20 {
    padding: 20px
}

.p-l-5 {
    padding-left: 5px;
}

.p-l-10 {
    padding-left: 10px;
}

.p-l-15 {
    padding-left: 15px;
}

.p-l-20 {
    padding-left: 20px;
}

.p-l-25 {
    padding-left: 25px;
}

.p-l-30 {
    padding-left: 30px;
}

.p-l-40 {
    padding-left: 40px;
}

.p-l-50 {
    padding-left: 50px;
}

.p-l-100 {
    padding-left: 100px;
}

.p-t-5 {
    padding-top: 5px;
}

.p-t-7 {
    padding-top: 7px;
}

.p-t-10 {
    padding-top: 10px;
}

.p-t-15 {
    padding-top: 15px;
}

.p-t-20 {
    padding-top: 20px;
}

.p-t-25 {
    padding-top: 25px;
}

.p-t-30 {
    padding-top: 30px;
}

.p-t-40 {
    padding-top: 40px;
}

.p-t-50 {
    padding-top: 50px;
}

.p-r-5 {
    padding-right: 5px;
}

.p-r-10 {
    padding-right: 10px;
}

.p-r-15 {
    padding-right: 15px;
}

.p-r-20 {
    padding-right: 20px;
}

.p-r-25 {
    padding-right: 25px;
}

.p-r-30 {
    padding-right: 30px;
}

.p-r-40 {
    padding-right: 40px;
}

.p-r-50 {
    padding-right: 50px;
}

.p-b-3 {
    padding-bottom: 3px;
}

.p-b-5 {
    padding-bottom: 5px;
}

.p-b-10 {
    padding-bottom: 10px;
}

.p-b-15 {
    padding-bottom: 15px;
}

.p-b-20 {
    padding-bottom: 20px;
}

.p-b-25 {
    padding-bottom: 25px;
}

.p-b-30 {
    padding-bottom: 30px;
}

.p-b-40 {
    padding-bottom: 40px;
}

.p-b-50 {
    padding-bottom: 50px;
}

.radius-5 {
    border-radius: 5px;
}

.radius-10 {
    border-radius: 10px;
}

.radius-20 {
    border-radius: 20px;
}

.radius-100 {
    border-radius: 100px;
}

.cursor-pointer {
    cursor: pointer;
}

.line-through {
    text-decoration: line-through;
}

.l-h-40 {
    line-height: 40px;
}


.position-absolute {
    position: absolute;
}

.position-relative {
    position: relative;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.horizontal-line {
    height: 1px;
    background: #d7d8da;
}

.display-flex {
    display: flex;
}

.flex-wrap {
    flex-wrap: wrap;
}

.flex-1 {
    flex: 1;
}

.r-c-c {
    display: flex;
    justify-content: center;
    align-items: center;
}

.r-sb-c {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.r-sb {
    display: flex;
    justify-content: space-between;
}

.r-se {
    display: flex;
    justify-content: space-evenly;
}

.r-ac {
    display: flex;
    align-items: center;
}

.r-flex-start {
    display: flex;
    align-items: flex-start;
}

.c-cb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.c-jc {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.c-c-c {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.c-sb-c {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.l-h-40 {
    line-height: 40px;
}