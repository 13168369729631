.custom-input-container {
    position: relative;
    /* margin-bottom: 20px; */
}

.custom-input-label {
    position: absolute;
    pointer-events: none;
    left: 0px;
    top: 5px;
    transition: top 0.3s ease, font-size 0.3s ease;
    font-size: 14px;
    color: #636363;
}

.custom-input {
    width: 100%;
    padding: 5px 0px;
    border: none;
    border-bottom: 1px solid #E0E0E0;
    outline: none;
    color: #636363;
    font-size: 14px;
    font-weight: 600;
}

.custom-input:focus {
    border-bottom: 1px solid #E0E0E0;
}

.custom-input:focus+.custom-input-label {
    top: -15px;
    font-size: 14px;
    color: #636363;
}

.custom-input-label.shrink {
    top: -15px;
    font-size: 12px;
    color: #636363;
}

.custom-autocomplete .ant-select-selector {
    border: none !important;
    background-color: transparent !important;
    border-bottom: 1px solid #E0E0E0 !important;
}

.custom-input-prefix{
    display: none;
}

.custom-input-label.shrink + .custom-input-prefix{
    display: inline-block;
}

.country-code {
    margin-left: 5px; /* Adjust spacing as needed */
    color: #636363; /* Same color as your input text */
}

.only-for-course-creation-label{
    color: #8c8c8c;
    font-size: 11px;
}

.custom-phone-input-enquiry.PhoneInput {
    border: 0px solid #FFFFFF !important;
    background-color: #FFFFFF !important;
    border-bottom: 1px solid #E0E0E0 !important;
    border-radius: 0px;
    height: 38px;
    outline: none;
}

.PhoneInputInput{
    border: none !important;
    background-color: transparent !important;
    outline: none;


}
.otpInputNew {
    background-color: #fff;
    display: flex;
    min-width: 40px;
    min-height: 40px;
    margin-right: 5px;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #AEAEAE;
  }


  .aulas-login__input-verify-otp{
    border: 0px;
    width: 265px;
    position: absolute;
    top: 0px;
    color: transparent;
    height: 40px;
    background-color: transparent;
    outline: none;
  }