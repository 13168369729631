.video-container {
    position: relative;
    width: 100%;
    height: 400px; /* Set the desired height */
    margin-bottom: 20px;
}

.video-thumbnail,
.video-player iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the video fills the container */
    border-radius: 16px;
}

.play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50px;
    height: 50px;
    background: url('../../../assets/NewAshreyaHome/video-play-btn.svg') no-repeat center;
    background-size: contain;
    cursor: pointer;
}

@media screen and (max-width: 800px) {
    .video-container {
        height: 230px;
        margin-bottom: 10px;
    }

    .play-button {
        width: 35px;
        height: 35px;
    }
}
