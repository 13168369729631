.new-login-page {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    display: flex;
}

.login-left-side {
    background-color: #E7E4EF;
    width: 40%;
}

.login-right-side {
    background-color: #F3F2F8;
    width: 60%;
}

.new-login-logo-parent {
    display: flex;
    align-items: center;
    position: absolute;
    left: 20px;
    top: 20px;
}

.new-login-institute-logo {
    width: 50px;
    height: 50px;
    margin-right: 10px;
}

.new-login-download-section {
    position: absolute;
    left: 20px;
    bottom: 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.new-login-download-child1 {
    display: flex;
    align-items: center;
    margin-right: 20px;
}

.new-login-store-img {
    margin-right: 20px;
    cursor: pointer;
    width: 100px;
    border-radius: 4px;
}

.login-card-parent {
    height: 60vh;
    border-radius: 28px;
    border: 7px solid #FFFFFF;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    background-color: #fff;
}

.login-card-parent.default-width {
    width: 76vw;
}

.login-card-parent.modal-width {
    width: 63vw;
}

.closable-icon-container {
    position: absolute;
    top: 15px;
    right: 20px;
    cursor: pointer;
    z-index: 10;
}

.new-carousel-parent {
    width: 36.7%;
    height: 100%;
    background-color: #F3F2F8;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 21px 0px 0px 21px;
    position: relative;
}

.react-multi-carousel-list.newLoginCarousel {
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.carousel-items-parent {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 35px;
    z-index: 10;
    
}

.carousel-image {
    width: 200px;
    margin-bottom: 20px;
}

.new-login-carousel-indicator {
    height: 7px;
    width: 7px;
    border-radius: 100px;
    cursor: pointer;
}

.new-login-form-parent {
    width: 63.3%;
    background-color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 0px 21px 21px 0px;
    position: relative;
    padding: 60px 20px;
}

.separator {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 12px;
}

.line {
    flex-grow: 1;
    height: 1px;
    background-color: #E6E6E6;
    border: none;
}

.or-text {
    color: #636363;
    font-size: 13px;
    white-space: nowrap;
    margin-top: -3px;
    margin: -3px 10px 0px 10px;
}

.otp-container {
    display: flex;
    gap: 10px;
}

.new-otp-input {
    background-color: #fff;
    width: 60px;
    height: 40px;
    text-align: center;
    border: none;
    border-bottom: 1px solid #AEAEAE;
}

.new-otp-input:focus {
    outline: none;
    border-bottom: 1px solid #AEAEAE;
}

.form-width {
    width: 65%;
}

.login-modal-form-width {
    width: 83%;
}

.forgot-email-field-margin {
    margin-bottom: 60px;
}

.email-field-margin {
    margin-bottom: 30px;
}

.login-success-modal-padding{
    padding: 20px;
}

/* .new-login-page .grecaptcha-badge {
    display: block !important;
} */

@media screen and (max-width: 1400px) {
    .form-width {
        width: 75%;
    }

    .login-modal-form-width {
        width: 90%;
    }
}

@media screen and (max-width: 800px) {
    .new-login-form-parent {
        width: 100%;
        height: 52%;
        background-color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border-radius: 0px;
        position: relative;
        padding: 20px;
    }

    .bottom-sheet-new-login-form-parent{
        width: 100%;
        height: 100%;
        background-color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border-radius: 0px;
        position: relative;
        padding: 30px 0px;
    }

    .form-width,
    .login-modal-form-width {
        width: 100%;
    }

    .new-otp-input {
        min-width: 40px;
    }

    .login-success-modal-padding{
        padding: 0px;
    }
}

.modal-round-corner .ant-modal-content {
    border-radius: 17px 
  }