.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
  }
  
  .bottom-sheet {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 400px;
    background-color: white;
    box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.2);
    border-radius: 20px 20px 0 0;
    padding: 20px;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    transform: translateY(100%);
  }
  
  .sheet-header {
    text-align: center;
    margin-bottom: 10px;
  }
  
  .drag-handle {
    width: 40px;
    height: 4px;
    background-color: #ccc;
    border-radius: 10px;
    margin: 0 auto 10px;
  }
  
  .sheet-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .input-field {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .verify-button {
    background-color: #6a1b9a;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .or-divider {
    text-align: center;
    margin: 20px 0;
  }
  
  .email-button {
    background-color: transparent;
    border: 1px solid #6a1b9a;
    color: #6a1b9a;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .open-sheet-button {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 10px;
    cursor: pointer;
  }
  
  .open-sheet-button:hover {
    background-color: #0056b3;
  }
  