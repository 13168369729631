.color-grey {
    color: #5B5462;
}

.color-black {
    color: #393e46;
}

.color-light-black {
    color: #5B5462
}
.color-light-grey {
    color: #BDBDBD
}

.color-green {
    color: #28DF99;
}

.color-pinkred {
    color: #F30E5C;
}

.color-white {
    color: #fff;
}

.color-grey-mid {
    color: #B0B2B5;
}

.color-primary {
    color: #623695;
}

.color-light-purple {
    color: #C199E7
}

.color-sec-primary {
    color: #5B3C90;
}

.color-sec-black {
    color: #140B1E;
}

.color-dark-black {
    color: #000000;
}