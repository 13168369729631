.terms-privacy-wrapper {
    padding-left: 10vw;
    padding-right: 10vw;
    line-height: 1.6;
}

.terms-policy-margintop {
    margin-top: 120px;
}

.terms-policy-marginBottom {
    margin-bottom: 100px;
}

@media screen and (max-width: 767px) {
    .terms-privacy-wrapper {
        padding-left: 20px;
        padding-right: 20px;
    }

    .terms-policy-margintop {
        margin-top: 70px;
    }

    .terms-policy-marginBottom {
        margin-bottom: 50px;
    }

}