.qr-width {
    width: 130px;
    height: 80px;
}

.mobile-footer-parent {
    display: none;
}

.web-footer-parent {
    padding-left: 6vw;
    padding-right: 6vw;
}

.download-our-app-div {
    display: flex;
    flex-direction: row;
    gap: 30px;
}

.register-parent {
    display: flex;
}

.qr-code-parent {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.download-div {
    margin-top: 26px;
}

.section-parent {
    text-align: left;
    width: fit-content;
    display: flex;
    flex-direction: column;
    gap: 10px
}

.address-parent {
    margin-top: 30px;
    width: 40%;
    display: flex;
    flex-direction: column;
    gap: 15px
}

.web-footer-child {
    padding-top: 20px;
    padding-bottom: 20px;
}

@media screen and (max-width: 1300px) {
    .web-footer-parent {
        padding-left: 100px;
        padding-right: 100px;
    }
}

@media screen and (max-width: 1200px) {
    .web-footer-parent {
        padding-left: 80px;
        padding-right: 80px;
    }
}

@media screen and (max-width: 1100px) {
    .web-footer-parent {
        padding-left: 60px;
        padding-right: 60px;
    }
}

@media screen and (max-width: 1000px) {
    .web-footer-parent {
        padding-left: 40px;
        padding-right: 40px;
    }

    .qr-width {
        width: 110px;
        margin-right: 20px;
    }
}

@media screen and (max-width: 1050px) {
    .web-footer-parent {
        padding-left: 30px;
        padding-right: 30px;
    }

    .qr-width {
        width: 100px;
        margin-right: 10px;
    }

    .register-div {
        margin-right: 20px;
    }
}

@media screen and (max-width: 900px) {
    .web-footer-parent {
        padding-left: 10px;
        padding-right: 10px;
    }

    .qr-width {
        width: 100px;
        margin-right: 10px;
    }

    .register-div {
        margin-right: 10px;
    }
}

@media screen and (max-width: 800px) {
    .web-footer-parent {
        padding-left: 20px;
        padding-right: 20px;
    }

    .web-footer-child {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .qr-width {
        width: 80px;
        height: 50px;
    }

    .download-our-app-div {
        display: flex;
        flex-direction: column;
        gap: 0px;
    }

    .register-parent {
        display: none;
    }

    .qr-code-parent {
        gap: 10px;
    }

    .download-div {
        margin-top: 20px;
    }

    .section-parent {
        gap: 5px
    }

    .address-parent {
        margin-top: 30px;
        width: 80%;
        display: flex;
        flex-direction: column;
        gap: 10px
    }
}