.responsive-padding {
    padding-left: 10vw;
    padding-right: 10vw;
}
/* .grecaptcha-badge {
    display: none !important;
  }
  
  .login-page .grecaptcha-badge {
    display: none !important; 
  } */

.web-banner1-bg-img,
.web-bg-img {
    display: block;
}

.mob-banner1-bg-img,
.mob-bg-img {
    display: none;
}

.explore-courses-icon {
    width: 65px;
    height: 65px;
}

.explore-courses-card {
    padding: 20px;
    border-radius: 32px;
    box-shadow: 4px 4px 16px 0px #0000002E;
    display: flex;
    align-items: center;
    height: fit-content;
    width: 100%;
    height: 180px;
    position: relative;
    overflow: hidden
}

/* .card .inner {
    padding: 20px;
    background: #FFFFFF;
    border-radius: 32px;
    position: relative;
    z-index: 1;
    width: 100%;
    margin: 2.5px;
    display: flex;
    height: 165px;
    align-items: center;
}

.card::before {
    content: "";
    position: absolute;
    top: -15px;
    left: -15px;
    right: -15px;
    bottom: -15px;
    border-radius: 32px;
    background: linear-gradient(to right, transparent, var(--border-color) 20%, transparent 50%),
        linear-gradient(to left, transparent, var(--border-color) 20%, transparent 50%);
    background-size: 100% 100%, 100% 100%;
    background-position: 0% 0%, 100% 0%;
    z-index: 0;
    transform-origin: center;
    transition: none;
}

.card.animate-border::before {
    animation: rotateBorder 1s linear 1;
} */

@keyframes rotateBorder {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/* .card.border-hidden {
    background: none;
}

.card.border-hidden::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    top: auto;
    right: auto;
    width: 80px;
    height: 80px;
    background: linear-gradient(45deg, var(--border-color) -10%, transparent 50%);
    border-radius: 0 32px 0 0;
    z-index: 0;
}

.card.border-hidden::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: auto;
    left: auto;
    width: 80px;
    height: 80px;
    background: linear-gradient(45deg, transparent 50%, var(--border-color) 100%);
    border-radius: 0 32px 0 0;
    z-index: 0;
} */

.experience-ashreya-app-card,
.new-faq-card {
    background-color: #DEE6FF;
    border-radius: 40px;
    width: 100%;
    height: fit-content;
    padding: 50px;
}

.mentorship-content-child1,
.mentorship-content-child2 {
    width: 50%;
    display: flex;
    flex-direction: column;
}

.shining-stars-content-child1 {
    width: 40%;
    display: flex;
    justify-content: space-between;
    gap: 30px;
}

.shining-stars-content-child2 {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.mentorship-content-child1 {
    align-items: flex-start;
    text-align: left;
}

.mentorship-content-child2 {
    align-items: flex-end;
    text-align: right;
}

.image-wrapper {
    width: 72%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 100;
}

.image-wrapper img {
    width: 100%;
    transition: opacity 0.5s ease-in-out;
}

/* .hover-image {
    position: absolute;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
}

.image-wrapper:hover .hover-image {
    opacity: 1;
}

.image-wrapper:hover .default-image {
    opacity: 0;
} */

/* .zoom img {
    width: 100%;
    transition: 0.3s all ease-in-out;
}

.zoom:hover img {
    transform: scale(1.1);
} */

.collapsible-cards {
    width: 100%;
    margin-top: 30px;
}

.collapsible-card {
    border: 1px solid #F7F9FF;
    margin-top: 20px;
    border-radius: 20px;
    overflow: hidden;
    transition: max-height 0.3s ease;
    background-color: #F7F9FF;
    padding: 20px;
    cursor: pointer;
}

.collapsible-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.collapsible-card-content {
    margin-top: 10px;
}

.banner-container {
    position: relative;
    width: 100vw;
    height: auto;
    margin-top: 50px;
    overflow: visible;
}

.banner-image {
    width: 100%;
}

.banner1-buttons {
    position: absolute;
    top: 55%;
    left: 9.7%;
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.banner-button {
    background-color: #FFEF00;
    padding: 10px 30px;
    border-radius: 8px;
    box-shadow: 4px 4px 16px 0px #00000040;
    border: 1px solid #FFEF00;
    display: flex;
    align-items: center;
    flex-direction: column;
    height: fit-content;
    cursor: pointer;
    width: fit-content;
    position: relative;
    overflow: hidden;
}

.banner-wrapper {
    width: 100vw;
    position: relative;
}

.banner-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 98.7%;
    object-fit: cover;
    z-index: 0;
}

.banner-content {
    position: relative;
    z-index: 1;
}

.banner-centered-box {
    position: absolute;
    bottom: -1%;
    left: 50%;
    transform: translate(-50%, 50%);
    z-index: 1;
}


/* .react-multi-carousel-list {
    overflow: visible !important;
} */

.banner-carousel-btn-group {
    margin-top: 20px;
    position: absolute;
    right: 30px;
    z-index: 111111
}

.enroll-now-btn {
    position: absolute;
    bottom: 15%;
    right: 33%;
    padding: 10px 24px;
    background-color: #FFEF00;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    z-index: 10000;
}

.floating-button {
    background-color: #FFEF00;
    padding: 19px 24px;
    border-radius: 12px;
    box-shadow: 4px 4px 16px 0px #00000040;
    border: 1px solid #FFEF00;
    display: flex;
    align-items: center;
    flex-direction: column;
    height: fit-content;
    position: fixed;
    bottom: 25px;
    right: 25px;
    z-index: 20000;
    cursor: pointer;
}

.banner-title2-margin {
    margin-top: 15px;
}

.full-image {
    width: 100%;
}

.explore-courses-heading-margin {
    margin-top: 60px;
}

.explore-courses-sub-heading-width {
    width: 80%;
}

.explore-courses-cards-parent {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 30px;
    width: 100%;
}

.ashreya-app-desc {
    margin-top: 30px;
    width: 40%;
}

.ashreya-app-content-parent {
    display: flex;
    justify-content: space-between;
    gap: 100px;
    width: 100%;
}

.ashreya-app-content-child1 {
    width: 45%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 50px;
}

.laptop-preview-margin {
    width: 65%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.learningApp-size {
    width: 100%;
    margin-bottom: -50px;
}

.more-about-ashreya-video {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 30px;
    margin-top: 50px;
}

.mentorship-heading-margin {
    margin-bottom: 50px;
}

.view-all-margin {
    margin-top: 30px;
}

.show-mob-mentorship-heading {
    display: none;
}

.show-web-mentorship-heading {
    display: block;
}

.shining-stars-img {
    width: 100%;
}

.blogs-sub-heading-width {
    width: 50%;
}

.testimonials-sub-heading-width {
    width: 55%;
}

.carousel-container {
    width: 100%;
    margin-top: 50px;
}

.testimonial-slide {
    display: flex;
    justify-content: center;
    align-items: center;
}

.testimonial-card {
    max-width: 600px;
    width: 100%;
    height: 290px;
    border-radius: 32px;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 4px 4px 16px 0px rgba(0, 0, 0, 0.18);
    background: #FFFFFF;
    z-index: 2;
}

.testimonial-card .inner {
    padding: 20px;
    background: #FFFFFF;
    border-radius: 32px;
    position: relative;
    z-index: 1;
    width: 100%;
    margin: 3px;
    height: 283px;
    width: 600px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.testimonial-card::before {
    content: "";
    position: absolute;
    top: -15px;
    left: -15px;
    right: -15px;
    bottom: -15px;
    border-radius: 32px;
    background: linear-gradient(to right,
            transparent,
            var(--border-color) 20%,
            transparent 50%),
        linear-gradient(to left,
            transparent,
            var(--border-color) 20%,
            transparent 50%);
    background-size: 100% 100%, 100% 100%;
    background-position: 0% 0%, 100% 0%;
    z-index: 0;
    animation: rotateBorder 1s linear 1;
}

@keyframes rotateBorder {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.testimonial-card.border-hidden {
    background: none;
}

.testimonial-card.border-hidden::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    top: auto;
    right: auto;
    width: 80px;
    height: 80px;
    background: linear-gradient(45deg, var(--border-color) -10%, transparent 50%);
    border-radius: 0 32px 0 0;
    z-index: 0;
}

.testimonial-card.border-hidden::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: auto;
    left: auto;
    width: 80px;
    height: 80px;
    background: linear-gradient(45deg, transparent 50%, var(--border-color) 100%);
    border-radius: 0 32px 0 0;
    z-index: 0;
}

.testimonial-card-image {
    width: 80px;
    height: 80px;
}

.student-images {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.student-image {
    position: absolute;
    cursor: pointer;
    transition: transform 0.3s ease, border 0.3s ease;
}

.student-image img {
    border-radius: 50%;
    width: 65px;
    height: 65px;
    border: 2px solid #43B5F5;
}

.student-image.highlighted img {
    /* border-color: #CF0058;
    transform: scale(1.1); */
}

.student-image.medium img {
    width: 55px;
    height: 55px;
    border: 2px solid #FF57B4
}

.student-image.small img {
    width: 45px;
    height: 45px;
    border: 2px solid #F28F25
}

.student-image:nth-child(1) {
    top: 33%;
    left: 20%;
}

.student-image:nth-child(2) {
    top: 33%;
    right: 20%
}

.student-image:nth-child(3) {
    top: 52%;
    right: 14%;
    transform: translate(100%, -50%);
}

.student-image:nth-child(4) {
    bottom: 24%;
    right: 23%;
}

.student-image:nth-child(5) {
    bottom: 24%;
    left: 23%;
}

.student-image:nth-child(6) {
    top: 52%;
    left: 14%;
    transform: translate(-100%, -50%);
}

.shining-star-sub-heading {
    text-align: left;
}

.features-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    border-radius: 16px;
    padding: 20px 30px;
    border: 1px solid #8A68B666
}

.feature-item {
    display: flex;
    align-items: center;
}

.feature-item img {
    width: 40px;
    height: 40px;
    margin-right: 15px;
}

.feature-text {
    display: flex;
    flex-direction: column;
    white-space: nowrap;
}

.feature-text div {
    width: auto;
    background-color: transparent;
}

.vertical-line {
    width: 1px;
    height: 40px;
    background-color: #8A68B666;
    margin: 0 20px;
}

.playStore-image {
    width: 150px;
    height: 100px;
}

.qrcode-image {
    width: 150px;
    height: 150px;
}

.blogsCard-parent {
    margin-top: 50px;
    gap: 30px;
    width: 100%;
}

.blogs-fit {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
    margin-top: 60px;
}

.blog-card {
    transition: transform 0.3s ease-in-out;
}

.blog-card:hover {
    transform: scale(1.05);
}


.truncate-2-lines {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 3rem;
}

.new-home-page-container {
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.full-bg-image {
    position: absolute;
    top: 0%;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
}

.explore-courses-container {
    position: relative;
    width: 100%;
    padding-top: 3.5rem;
    padding-bottom: 7rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.experience-ashreya-app-container,
.more-about-ashreya-container,
.blogs-container,
.testimonials-container {
    position: relative;
    width: 100%;
    display: flex;
    padding-bottom: 7rem;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    z-index: 1;
}

.mentorship-container,
.shining-stars-container {
    position: relative;
    width: 100%;
    padding-bottom: 7rem;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    z-index: 1;
}

.shining-stars-container {
    padding-bottom: 2rem;
}

.new-faq-container {
    position: relative;
    width: 100%;
    padding-bottom: 7rem;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: flex-end;
}

.new-faq-card {
    margin-top: -80px;
}

.testimonial-slider-height {
    height: fit-content;
}

.blog-descriptionText {
    width: 100%;
    text-align: left !important;
    display: inline-block;
    margin-right: 0 !important;
    cursor: pointer;
}

.back-btn {
    background-color: #623695;
    height: 40px;
    width: 70px;
    margin-top: 5px;
    left: 12%
}

.blogs-margin {
    margin-top: 80px;
    margin-bottom: 40px;
}

.blogs-purple-box {
    height: fit-content;
    width: 60%;
    background-color: #623695;
    color: white;
    position: absolute;
    bottom: -30px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    padding: 20px 30px;
}

.blogs-paragraph {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 30px;
    margin: 100px 0px
}

.laptop-container {
    width: 100%;
    margin: 0 auto;
    position: relative;
    background-image: url('../../../assets/NewAshreyaHome/laptop-preview-image.svg');
    background-size: contain;
    background-repeat: no-repeat;
    padding-top: 60%;
    z-index: 1;
    /* Ensure laptop carousel is behind mobile carousel */
}

.screen {
    position: absolute;
    top: 11%;
    left: 12%;
    width: 76.5%;
    height: 100%;
    overflow: hidden;
}

.screenshot {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.mobile-container {
    width: 100%;
    margin: 0 auto;
    position: absolute;
    top: 10%;
    left: -15%;
    background-image: url('../../../assets/NewAshreyaHome/mobile-preview-image.svg');
    background-size: contain;
    background-repeat: no-repeat;
    padding-top: 60%;
    z-index: 2;
}

.mobile-screen {
    position: absolute;
    top: 16%;
    left: 10.8%;
    width: 18.6%;
    height: 100%;
    overflow: hidden;
    border-radius: 16px;
}

.mobile-screenshot {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 16px;
}

.container {
    background-image: url('../../../assets/NewAshreyaHome/static-bg-image.svg');
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    min-height: 100vh;
    position: relative;
    overflow: hidden;
}

.content {
    min-height: 100vh;
}

.more-about-ashreya-child {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: center;
    width: 100%
}

.glare {
    background-image: linear-gradient(to right, #ffffffbe 24%, #fff0 26% 53%, #ffffffbe 54%);
    width: 40px;
    height: 100%;
    position: absolute;
    inset: 0% 0% 0% -21%;
    transform-style: preserve-3d;
    will-change: transform;
    z-index: 1;
    animation: fadeInRight 3s infinite forwards;
    animation-delay: 1s;
}

.enroll-btn-glare {
    background-image: linear-gradient(to right, #ffffffbe 24%, #fff0 26% 53%, #ffffffbe 54%);
    width: 40px;
    height: 100%;
    position: absolute;
    inset: 0% 0% 0% -21%;
    transform-style: preserve-3d;
    will-change: transform;
    z-index: 1;
    animation: btnFadeInRight 1.5s infinite forwards;
}

.card-glare {
    width: 40px;
    height: 100%;
    position: absolute;
    inset: 0% 0% 0% -21%;
    transform-style: preserve-3d;
    will-change: transform;
    z-index: 1;
}

@keyframes btnFadeInRight {
    0% {
        transform: translate3d(2.5rem, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(-30deg, 0deg);
        opacity: 1;
    }

    100% {
        transform: translate3d(7.9rem, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(-30deg, 0deg);
        opacity: 0.4;
    }
}

@keyframes fadeInRight {
    0% {
        transform: translate3d(0rem, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(-30deg, 0deg);
        opacity: 1;
    }

    100% {
        transform: translate3d(40rem, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(-30deg, 0deg);
        opacity: 0.2;
    }
}

.shining-stars-info {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
}

.blogs-back-btn {
    background-color: #623695;
    height: 40px;
    width: 70px;
    top: 0%;
    left: 10%;
    position: absolute;
}

.highlighted-text {
    font-family: 'Marck Script', cursive;
    font-size: 1.5rem;
    font-weight: 400;
    text-align: left;
    margin-top: 20px;
}

.live-classes {
    color: #007E58
}

.recorded-videos {
    color: #DB3406;
}

.notes-practice {
    color: #401995;
}

.online-exams {
    color: #E53BC6;
}

@media screen and (max-width: 1400px) {
    .banner-button {
        padding: 10px 20px;
    }

    .ashreya-app-content-child1 {
        width: 50%;
    }
}

@media screen and (max-width: 1300px) {
    .banner-container {
        margin-top: 48px;
    }

    .responsive-padding {
        padding-left: 100px;
        padding-right: 100px;
    }

    .banner1-buttons {
        top: 50%;
    }

    .testimonial-card {
        max-width: 500px !important;
    }

    .testimonial-card .inner {
        width: 500px !important;
    }
}

@media screen and (max-width: 1200px) {
    .responsive-padding {
        padding-left: 80px;
        padding-right: 80px;
    }

    .ashreya-app-content-child1 {
        width: 50%;
        margin-right: 0px;
    }
}

@media screen and (max-width: 1100px) {
    .responsive-padding {
        padding-left: 60px;
        padding-right: 60px;
    }

    .testimonial-card {
        max-width: 400px !important;
    }

    .testimonial-card .inner {
        width: 400px !important;
    }
}

@media screen and (max-width: 1050px) {
    .responsive-padding {
        padding-left: 30px;
        padding-right: 30px;
    }
}

@media screen and (max-width: 1000px) {
    .testimonial-card {
        max-width: 400px;
    }

    .testimonial-card .inner {
        width: 400px;
    }

    .features-container {
        padding: 15px;
    }

    .ashreya-app-content-parent {
        flex-direction: column;
        gap: 30px;
    }

    .ashreya-app-content-child1 {
        gap: 20px;
        width: 100%;
        margin-top: 20px;
        justify-content: space-around;
    }

    .laptop-preview-margin {
        left: 20%;
    }
}

@media screen and (max-width: 900px) {
    .banner-container {
        margin-top: 45px;
    }

    .banner-title2-margin {
        margin-top: 10px;
    }

    .responsive-padding {
        padding-left: 10px;
        padding-right: 10px;
    }

    .learningApp-size {
        margin-top: 20px;
    }
}

@media screen and (max-width: 800px) {

    .web-banner1-bg-img,
    .web-bg-img {
        display: none;
    }

    .mob-banner1-bg-img {
        display: block;
    }

    .banner-image {
        width: 101%;
    }

    .mob-bg-img {
        display: block;
    }

    .banner-title2-margin {
        margin-top: 10px;
    }

    .banner-button {
        padding: 8px 15px;
    }

    .banner1-buttons {
        position: absolute;
        top: 40%;
        left: 4%;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .explore-courses-heading-margin {
        margin-top: 20px;
        /* margin-bottom: 30px; */
    }

    .explore-courses-sub-heading-width {
        width: 100%;
    }

    .explore-courses-cards-parent {
        flex-direction: column;
        gap: 20px;
    }

    .card {
        height: fit-content;
        border-radius: 16px;
    }

    .card .inner {
        margin: 2px;
        height: fit-content;
        border-radius: 16px;
        padding: 15px;
    }

    .card.border-hidden::after {
        border-radius: 0 16px 0 0;
    }

    .explore-courses-icon {
        width: 45px;
        height: 45px;
    }

    .responsive-padding {
        padding-left: 20px;
        padding-right: 20px;
    }

    .experience-ashreya-app-card {
        border-radius: 20px;
        padding: 28px;
    }

    .ashreya-app-desc {
        margin-top: 15px;
        width: 100%;
    }

    .ashreya-app-content-parent {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
    }

    .laptop-preview-margin {
        width: 80%;
        left: 13%;
    }

    .learningApp-size {
        width: 100%;
        margin-bottom: -30px;
        margin-top: 0px;
    }

    .mentorship-container,
    .shining-stars-container {
        flex-direction: column;
    }

    .more-about-ashreya-video {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        gap: 30px;
        margin-top: 30px;
    }

    .floating-button {
        padding: 12px;
        border-radius: 8px;
    }

    .mentorship-content-child1,
    .mentorship-content-child2 {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .arraow-image-size {
        width: 20px;
        height: 20px;
    }

    .mentorship-content-child2 {
        align-items: center;
        text-align: center;
        order: 1;
        width: 70%;
    }

    .mentorship-content-child1 {
        order: 2;
        margin-top: 20px;
    }

    .shining-stars-bg-img {
        flex-direction: column;
    }

    .new-faq-card {
        border-radius: 20px;
        padding: 28px;
    }

    .hide-faq-icon {
        display: none;
    }

    .view-all-margin {
        margin-top: 20px;
    }

    .show-mob-mentorship-heading {
        display: block;
    }

    .show-web-mentorship-heading {
        display: none;
    }

    .shining-stars-content-child1 {
        order: 2;
        width: 100%;
        margin-top: 20px;
    }

    .shining-stars-content-child2 {
        order: 1;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .shining-stars-img {
        width: 60%;
    }

    .blogs-sub-heading-width,
    .testimonials-sub-heading-width {
        width: 100%;
    }

    .testimonial-card {
        max-width: 250px !important;
    }

    .testimonial-card .inner {
        width: 250px !important;
    }

    .shining-star-sub-heading {
        text-align: center;
    }

    .webBanner1-box-image {
        width: 330px;
    }

    .vertical-line {
        margin: 0 15px;
    }

    .feature-item img {
        width: 30px;
        height: 30px;
        margin-right: 10px;
    }

    .features-container {
        padding: 8px 10px;
        border-radius: 12px;
    }

    .collapsible-card {
        border-radius: 12px;
        padding: 12px 14px;
        margin-top: 14px;
    }

    .blogsCard-parent {
        margin-top: 30px;
        gap: 20px;
    }

    .blogs-fit {
        grid-template-columns: repeat(2, 1fr);
    }

    .explore-courses-container {
        padding-top: 5rem;
        padding-bottom: 5rem;
    }

    .experience-ashreya-app-container,
    .more-about-ashreya-container,
    .blogs-container,
    .testimonials-container,
    .mentorship-container,
    .new-faq-container {
        padding-bottom: 4rem;
    }

    .shining-stars-container {
        padding-bottom: 2rem;
    }

    .testimonials-container {
        padding-bottom: 3rem;
    }

    .truncate-2-lines {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 1.3;
        max-height: 2.6em;
        flex-grow: 1;
    }

    .back-btn {
        height: 30px;
        width: 40px;
        left: 10%;
    }

    .blogs-margin {
        margin-top: 80px;
        margin-bottom: 40px;
    }

    .blogs-purple-box {
        width: 80%;
        padding: 10px;
    }

    .blogs-paragraph {
        gap: 20px;
        margin: 60px 0px
    }

    .container {
        background-image: url('../../../assets/NewAshreyaHome/static-mobile-bg-image.svg');
    }

    .explore-courses-card {
        height: fit-content;
        border-radius: 12px;
    }

    .shining-stars-info {
        gap: 4px;
    }

    .blogs-back-btn {
        height: 30px;
        width: 40px;
        left: 5%;
        top: -30px
    }

    .highlighted-text {
        font-family: 'Marck Script', cursive;
        font-size: 1rem;
        font-weight: 400;
        text-align: left;
        margin-top: 20px;
    }

    .banner-centered-box {
        bottom: 0%;
    }

    .banner-carousel-btn-group {
        margin-top: 52px;
        right: 10px;
    }

    .enroll-now-btn {
        position: absolute;
        bottom: 50px;
        left: 81%;
        transform: translateX(-50%);
        padding: 10px 15px;
        width: 120px;
        height: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .enroll-btn-glare {
        background-image: linear-gradient(to right, #ffffffbe 24%, #fff0 26% 53%, #ffffffbe 54%);
        width: 40px;
        height: 100%;
        position: absolute;
        inset: 0% 0% 0% -21%;
        transform-style: preserve-3d;
        will-change: transform;
        z-index: 1;
        animation: btnFadeInRight 1.5s infinite forwards;
    }

    @keyframes btnFadeInRight {
        0% {
            transform: translate3d(2.4rem, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(-30deg, 0deg);
            opacity: 1;
        }

        100% {
            transform: translate3d(5.8rem, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(-30deg, 0deg);
            opacity: 0.4;
        }
    }
}

@media screen and (max-width: 600px) {

    .mentorship-content-child1,
    .mentorship-content-child2 {
        width: 100%;
    }

    .questionBro-size {
        width: 25%;
    }

    .new-faq-card {
        margin-top: -65px;
    }

    .student-image img {
        border-radius: 50%;
        width: 55px;
        height: 55px;
        border: 2px solid #43B5F5;
    }

    .student-image.medium img {
        width: 45px;
        height: 45px;
        border: 2px solid #FF57B4
    }

    .student-image.small img {
        width: 35px;
        height: 35px;
        border: 2px solid #F28F25
    }

    .testimonial-card-margin {
        margin-top: 115px;
    }

    .testimonial-card {
        max-width: 98% !important;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
        height: fit-content;
    }

    .testimonial-card .inner {
        margin: 3px;
        width: 100% !important;
        height: fit-content;
    }

    .student-image:nth-child(1) {
        top: 25%;
        left: 12%;
    }

    .student-image:nth-child(2) {
        top: 81%;
        right: 12%;
    }

    .student-image:nth-child(3) {
        top: 18%;
        right: 40%;
        transform: translate(100%, -50%);
    }

    .student-image:nth-child(4) {
        top: 25%;
        right: 13%;
    }

    .student-image:nth-child(5) {
        bottom: 7%;
        left: 12%;
    }

    .student-image:nth-child(6) {
        top: 93%;
        left: 48%;
    }

    .playStore-image {
        width: 100px;
        height: 50px;
    }

    .qrcode-image {
        width: 100px;
        height: 100px;
    }

    .testimonial-slider-height {
        height: 480px;
    }

    .laptop-preview-margin {
        width: 95%;
        left: 6%;
    }
}

@media screen and (max-width: 420px) {
    .testimonial-slider-height {
        height: 500px;
    }

    .new-faq-card {
        margin-top: -45px;
    }
}