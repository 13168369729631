.new-header-parent {
    padding-top: 10px;
    padding-bottom: 10px;
    width: 100%;
    background-color: #594099;
    height: 7vh;
}

.new-header-child1 {
    width: 65%;
}

.ashreya-white-logo-size {
    width: 20%
}

.new-header-child2 {
    height: 7vh;
    margin-right: -40px;
}

.header-subDomain {
    width: 8vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.login-register-btn-style {
    background-color: #FFEF00;
    border-radius: 8px;
    padding: 6px 24px;
    border: 1px solid #FFEF00;
    height: fit-content;
    cursor: pointer;
}

.new-mobile-header-parent {
    display: none;
}

.new-top-bar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    transition: transform 0.1s ease-in-out;
    z-index: 200000;
}

.visible {
    transform: translateY(0);
}

.hidden {
    transform: translateY(-100%);
}

@media screen and (max-width: 1300px) {
    .new-header-child2 {
        height: 7vh;
        margin-right: -10px;
    }
    .new-header-child1 {
        width: 50%;
    }

    .ashreya-white-logo-size {
        width: 25%
    }
}

@media screen and (max-width: 1100px) {
    .new-header-child1 {
        width: 45%;
    }

    .ashreya-white-logo-size {
        width: 30%
    }
}

@media screen and (max-width: 800px) {
    .new-header-parent {
        display: none;
    }

    .new-mobile-header-parent {
        display: flex;
        width: 100%;
        padding: 10px 20px;
        background-color: #623695;
    }

    .mobile-header-logo-size {
        width: 20%;
    }
}

@media screen and (max-width: 600px) {
    .mobile-header-logo-size {
        width: 30%;
    }
}