@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;500;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");

.header-font {
    font-family: bebas neue !important;
}

.text-xxs {
    font-family: Lato;
    font-size: 0.75rem;
}

.text-xs {
    font-family: Lato;
    font-size: 0.875rem;
}

.text-xsm {
    font-family: Lato;
    font-size: 1rem;
}

.text-sm {
    font-family: Lato;
    font-size: 1.125rem;
}

.text-20 {
    font-family: Lato;
    font-size: 1.25rem;
}
.text-24{
    font-family: Lato;
    font-size: 1.5rem;
}

.text-md {
    font-family: Lato;
    font-size: 1.375rem;
}

.text-lg {
    font-family: Lato;
    font-size: 1.625rem;
}

.text-xlg {
    font-family: Lato;
    font-size: 1.875rem;
}

.text-35 {
    font-family: Lato;
    font-size: 35px;
}

.text-40 {
    font-family: Lato;
    font-size: 40px;
}

.text-45 {
    font-family: Lato;
    font-size: 45px;
}

.text-50 {
    font-family: Lato;
    font-size: 50px;
}

.text-55 {
    font-family: Lato;
    font-size: 55px;
}

.text-60 {
    font-family: Lato;
    font-size: 60px;
}

.text-spacing {
    letter-spacing: 0.25em;
}


.font-400 {
    font-weight: 400;
}

.font-500 {
    font-weight: 500;
}

.font-600 {
    font-weight: 600;
}

.font-700 {
    font-weight: 700;
}

.font-800 {
    font-weight: 800;
}

.font-900 {
    font-weight: 900;
}

.font-bold {
    font-weight: bold;
}